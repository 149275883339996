


import React from "react";

import a1 from '../../../assets/garment/image7.jpg'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">

          <div className="row">
            <div className="col-lg-6">
              <div className="image">
                <img src={a1} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="container">
                <h3 className="subtitle mt-4">{t('Clothes handcrafted with love.')}</h3>
                <p className="">
                  {/* {data?.about_company} */}
                  Our brand philosophy stands for “Clothing that is wearable yet stylish”. The brand’s USP are it’s prints, clean cuts and painstakingly hand crafted designs. Every three months, a new collection is conceived keeping in mind the season and colours and a theme is chosen. Once this is finalised, a dedicated team of skilled dyers, printers, embroiders, masters and tailors work their magic to create the final product.

                </p>
                <p>
                  {t('about-1')}
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default AboutStory;
