import React, { useEffect, useState } from "react";
import AboutBanner from "./about-banner/AboutBanner";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import AboutStory from "./about-story/AboutStory";
import AboutChoose from "./about-choose/AboutChoose";
import AboutCounters from "./about-counters/AboutCounters";
import axios from "axios";
import { useTranslation } from "react-i18next";

function About() {

  const [data, setData] = useState()
  const aboutData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/adminWeb_footer/get`)
      setData(res.data)
    } catch (error) {
      // alert('server-error')
    }

  }
  useEffect(() => {
    aboutData()
  }, [])
  const { t, i18n } = useTranslation()
  return (
    <>
      <Breadcrumb title="About Us" t={t} />
      <main className="main about">
        {/* <AboutBanner /> */}
        <AboutStory data={data} t={t} />
        <AboutChoose t={t} />
        {/* <AboutCounters t={t}/> */}
      </main>
    </>
  );
}

export default About;
