import React from "react";
import { Helmet } from "react-helmet";
import { useGetTermsConditionQuery } from "../products/productSlice";

function TermsOfUse() {
  const { data, isLoading } = useGetTermsConditionQuery();
  // console.log(data);
  return (
    <>
      <Helmet>
        <title>Terms & Condition | Fertilizer Multi Vendor</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <section className="termsSec">
        <div className="container">
          <div className="termsInfo">
            {/* {isLoading && (
              <div className="preloaderCount">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )} */}
            <h4>Terms & Condition</h4>
            <div class="panel-body">

              <p>Last updated: 2023-12-23</p>
              <p>
                1. <strong>Introduction</strong>
              </p>
              <p>Welcome to the SHIKHA MALIK web site. We provide this site as a service to its customers. Please read the following terms of service  as they govern your use of our site. By using this Site, you agree to follow and be bound by the following rule below. Site users must agree upon and abide by these changes accordingly. Please review this page periodically for changes. Any use of our site at any time constitutes full acceptance of our Service Terms.</p>

              2. <strong>Usage Restrictions</strong>

              <p>
                All of the content that appears on this web site, including all visuals, text, audio and video clips are subject to copyright protections. We are happy for people to share our images, but please make sure that you credit our Brand and link to shikhamalik.com
              </p>
              <p>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements. Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence. Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.</p>
              <p>
                3. <strong>Notice</strong>
              </p>
              <p>
                Shikha Malik may contact you or provide you with service-related and/ or promotional notices by means of postal mail, electronic mail, general site notifications and more using the contact information you have provided to us.
              </p>

              <p>
                4. <strong>Pricing Policy</strong>
              </p>
              <p>
                Prices are exclusive of Taxes, but not any custom charges that may occur for international deliveries, or transaction fees that you may be charged by your bank for making a payment in a different currency to your holding account. We reserve the right to adjust any prices or shipping amounts at any time without notice.
              </p>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsOfUse;
