import React, { useEffect, useState } from "react";
import {
  useGetCategoriesQuery,
  useGetMenuListQuery,
} from "../../products/productSlice";

import "./ProductsCategory.css";
import axios from "axios";

import service1 from "../../../assets/garment/category/c1.jpg";
import service2 from "../../../assets/garment/category/c2.jpg";
import service3 from "../../../assets/garment/category/c3.jpg";
import service4 from "../../../assets/garment/category/c4.avif";
import service5 from "../../../assets/garment/category/c5.jpg";
import service6 from "../../../assets/garment/category/c6.jpg";
import service7 from "../../../assets/garment/category/c7.avif";
import service8 from "../../../assets/garment/category/c8.jpg";
import service9 from "../../../assets/garment/category/c9.jpg";
import service10 from "../../../assets/garment/category/c10.jpg";
import service11 from "../../../assets/garment/category/c11.avif";
import service12 from "../../../assets/garment/category/c12.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const categoriesItems = [
  { id: "1", imgUrls: service1, title: "Women t-shirts", count: '23' },
  { id: "2", imgUrls: service2, title: "Men t-shirts", count: '23' },
  { id: "3", imgUrls: service3, title: "Kids", count: '23' },
  { id: "4", imgUrls: service4, title: "Festive Wear", count: '23' },
  { id: "5", imgUrls: service5, title: "Ethnic Wear", count: '23' },
  { id: "6", imgUrls: service6, title: "Men", count: '23' },
  { id: "7", imgUrls: service7, title: "Women", count: '23' },
  { id: "8", imgUrls: service8, title: "Kurta Pant Set", count: '23' },
  { id: "9", imgUrls: service9, title: "Lehenga Set", count: '23' },
  { id: "10", imgUrls: service10, title: "Sharara Set", count: '23' },
  { id: "11", imgUrls: service11, title: "Sherwanis", count: '23' },
  { id: "12", imgUrls: service12, title: "Lehangas", count: '23' },
]
function ProductsCategory() {
  // const { isLoading, error } = useGetCategoriesQuery();

  const [categoriesDatas, setCateData] = useState(null)

  const getcateData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/category/public`, { withCredentials: true })
      setCateData(res.data)
    } catch (error) {

    }
  }
  useEffect(() => {
    getcateData()
  }, [])
  const { t, i18n } = useTranslation()
  return (
    <>
      <section className="serviceList sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content">
                <h3> {t('SHOP BY CATEGORY')}</h3>
                <p>
                </p>
              </div>
            </div>

            <div className="row">
              {/* {isLoading && (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {error && (
                <h4 style={{ textAlign: "center", color: "red" }}>
                  Server Error
                </h4>
              )} */}


              {/* <div className="catgBox"> */}
              {categoriesItems?.map((item, i) => {
                return (
                  <div className="col-lg-3" key={item?._id}>
                    <div className="serviceItem mediaQuaryClas mediaQuaryClas-2">
                      <Link to={`/product/category/${item.uid}/${item?.slug}`} className="taxonomy-link">
                        <div className="serviceItemIcon taxonomy-thumb">
                          <img
                            src={item?.imgUrls}
                            alt="Product"
                            className="img-fluid"
                          />
                        </div>
                        <span className="taxonomy-title" data-hover="Women t-shirts" style={{ fontSize: '15px' }}>{item?.title}</span>
                        <span className="taxonomy-count">18</span>
                      </Link>
                    </div>
                  </div>
                );
              })}
              {/* </div> */}
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;
