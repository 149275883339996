import mamaspro from "../../assets/img/products/mp4.1.webp";
import { BsFillCartFill } from "react-icons/bs"
import { FiSearch } from "react-icons/fi"
import { GrAdd } from "react-icons/gr"
import { Link, useNavigate } from "react-router-dom";
import { CustomToaster } from "../../common/toaster/CustomToaster";
import { useEffect, useState } from "react";
import { setCartLeng, useSetCartMutation } from "../products/productSlice";
import { useDispatch } from "react-redux";

function CatogaryItem({ item, i, handleShow, t }) {
    const curr = window.localStorage.getItem('currencySym')
    let currencySymbol = curr
    if (currencySymbol === 'undefined') {
        currencySymbol = ' QAR'
    }
    const [showTaoster, setShowToaster] = useState({ show: false, message: '', color: 'success' })
    const navigate = useNavigate()
    const handleToaster = () => {
        setShowToaster({ ...showTaoster, show: false })
    }
    const [names, setNames] = useState('')
    const [addToCart, { data: datacart, isLoading: isAddCartLoading, isSuccess: isAddToCartSuccess, isError: isAddToCartError }] = useSetCartMutation();
    const BuyNowItem = (item, str) => {
        // if (window.localStorage.getItem('isLogin') == 'false') {
        //     alert('Login First')
        //     return
        // }
        setNames(item.name)
        const payload = {
            qty: 1,
            pickupPoint: null,
            variantId: item.variations[0]?.uid,
            productId: item.uid,
            deliveryType: 'HOME DELIVERY',
            seller_id: item.prices?.seller_id,
            sku: item.prices?.sku,
        }
        addToCart(payload)

        setTimeout(() => {
            navigate('/checkout')
        }, 1000);
    }
    const dispacher = useDispatch()
    useEffect(() => {
        if (isAddToCartSuccess) {
          dispacher(setCartLeng(datacart?.cartLength))
          setShowToaster({ show: true, message: 'Product added successfully!', color: 'success' });
        }
        if (isAddToCartError) {
          setShowToaster({ show: true, message: 'Something went wrong Product Not Add', color: 'danger' })
        }
      }, [isAddToCartSuccess, isAddToCartError])

    return <div className="col-lg-3 col-md-6 col-sm-12 cusname" key={item._id}>
        <CustomToaster color={showTaoster.color} title={names} show={showTaoster.show} setShow={handleToaster} message={showTaoster.message} position="bottom-end" delay={10000} />
        <div className="featuredInfo">
            <div className="featuredFigure">
                <div className="featuredImg">
                    <Link to={`/product/${item.uid}/${item.slug}`}>
                        {item?.variations[0]?.mainImage_url?.url ? <img src={item?.variations[0]?.mainImage_url.url} alt="Product" className="imgProduct" /> : <img src={mamaspro} alt="Product" />}
                    </Link>
                    <div className="quickView d-none">
                        <ul>
                            <li className="viewProduct">
                                <button
                                    className="quick_view_btn"
                                    onClick={(e) => {
                                        handleShow(item._id)
                                    }}
                                >
                                    <FiSearch />
                                </button>
                            </li>
                            <li className="addProduct">
                                <Link to="/products">
                                    <GrAdd />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul className="hotList d-none">
                    <li>
                        <Link to="/products">hot</Link>
                    </li>
                    <li>
                        <Link to="products">-20%</Link>
                    </li>
                </ul>
            </div>
            <div className="featuredContent">
                <h6 className="d-none">category</h6>
                <h5 >
                    <Link to={`/product/${item.uid}/${item.slug}`}>{item.name}</Link>
                </h5>
                {item?.prices ? (<div className="rateDigit">
                    <span className="currentPrice">{item?.country?.code}  {item?.prices?.sale_rate}</span>
                </div>) : (<div className="rateDigit"> <span className="currentPrice">{item?.variations[0]?.prices.country_id?.code}  {item?.variations[0]?.prices.sale_rate}</span>
                </div>)}


                <div className="buyNowInfo">

                    <Link to={`/product/${item.uid}/${item.slug}`} className="btn btn-danger addCart  d-none">View Detail</Link>
                    <Link to="#" className="buyNow" onClick={() => { BuyNowItem(item) }}>
                        <BsFillCartFill /> {t('Buy Now')}
                    </Link>


                </div>
                <div className="productDesc">
                    <p>{item.meta_description}</p>
                </div>
                <div className="featuredOption">
                    <select defaultValue={"DEFAULT"}>
                        <option value="DEFAULT">Select Option</option>
                        <option value="one">One</option>
                        <option value="two">Two</option>
                    </select>
                </div>
            </div>
        </div>



    </div>
}
export default CatogaryItem