import React, { useState } from "react";

import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { BiLoaderAlt } from "react-icons/bi";

import f1 from '../../../../assets/garment/tranding-product/t1.avif'
import f2 from '../../../../assets/garment/tranding-product/t2.avif'
import f3 from '../../../../assets/garment/tranding-product/t3.avif'
import f4 from '../../../../assets/garment/tranding-product/t4.avif'
import f5 from '../../../../assets/garment/tranding-product/t5.avif'
import f6 from '../../../../assets/garment/tranding-product/t6.avif'

// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css";

import { Link, useNavigate } from "react-router-dom";
import {
  setCartLeng,
  setFeachers,
  useGetProductDetailQuery,
  useSetCartMutation,
} from "../../../products/productSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ImgesData } from "../../proImgs/ImgesData";
import { useTranslation } from "react-i18next";
import { CustomToaster } from "../../../../common/toaster/CustomToaster";

function FeaturedProduct({ isLoading, data, error, handleShow, }) {

  const curr = window.localStorage.getItem("currencySym");
  const currencySymbol = curr ? curr : "QAR";
  const dispacher = useDispatch();

  useEffect(() => {
    dispacher(setFeachers(data));
  }, [data]);

  const [count, setCount] = useState(8)
  const changeLoad = () => {
    setCount(count + 4)
  }
  const { t, i18n } = useTranslation()


  const [addToCart, { data: datacart, isLoading: isAddCartLoading, isSuccess: isAddToCartSuccess, isError: isAddToCartError }] = useSetCartMutation();
  const [names, setNames] = useState('')
  const handleAddCart = (item) => {
    setNames(item.name)
    const payload = {
      qty: 1,
      pickupPoint: null,
      variantId: item.variations[0]?.uid,
      productId: item.uid,
      deliveryType: 'HOME DELIVERY',
      seller_id: item.variations[0]?.prices?.seller_id,
      sku: item.variations[0]?.prices?.sku,
    }
    addToCart(payload)
  };
  const [showTaoster, setShowToaster] = useState({ show: false, message: '', color: 'success' })
  useEffect(() => {
    if (isAddToCartSuccess) {
      dispacher(setCartLeng(datacart?.cartLength))
      setShowToaster({ show: true, message: 'Product added successfully!', color: 'success' });
    }
    if (isAddToCartError) {
      setShowToaster({ show: true, message: 'Something went wrong Product Not Add', color: 'danger' })
    }
  }, [isAddToCartSuccess, isAddToCartError])

  const handleToaster = () => {
    setShowToaster({ ...showTaoster, show: false })
  }



  // Buy Now
  const navigate = useNavigate()

  const BuyNowItem = (item) => {
    // if (window.localStorage.getItem('isLogin') == 'false') {
    //   alert('Login First')
    //   return
    // }
    setNames(item.name)
    const payload = {
      qty: 1,
      pickupPoint: null,
      variantId: item.variations[0]?.uid,
      productId: item.uid,
      deliveryType: 'HOME DELIVERY',
      seller_id: item.variations[0]?.prices?.seller_id,
      sku: item.variations[0]?.prices?.sku,
    }
    addToCart(payload)
    setTimeout(() => {
      navigate('/checkout')
    }, 1000);
  }

  const categoriesItems = [
    { id: "1", imgUrls: f1, title: "Women t-shirts", count: '23' },
    { id: "2", imgUrls: f2, title: "Men t-shirts", count: '23' },
    { id: "3", imgUrls: f3, title: "Kids", count: '23' },
    { id: "4", imgUrls: f4, title: "Festive Wear", count: '23' },
    { id: "5", imgUrls: f5, title: "Ethnic Wear", count: '23' },
    { id: "6", imgUrls: f6, title: "Men", count: '23' },
    { id: "7", imgUrls: f1, title: "Women", count: '23' },
    { id: "8", imgUrls: f2, title: "Kurta Pant Set", count: '23' },
    { id: "9", imgUrls: f3, title: "Lehenga Set", count: '23' },
    // { id: "10", imgUrls: f1, title: "Sharara Set", count: '23' },
    // { id: "11", imgUrls: f1, title: "Sherwanis", count: '23' },
    // { id: "12", imgUrls: f1, title: "Lehangas", count: '23' },
  ]


  return (
    <>
      <CustomToaster color={showTaoster.color} title={names} show={showTaoster.show} setShow={handleToaster} message={showTaoster.message} position="bottom-end" delay={10000} />
      {isLoading && (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      )}
      {/* categoriesItems?.slice(0, count).map((item, i) */}
      {categoriesItems &&
        categoriesItems?.map((item, i) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-12 cusname" key={item._id}>
              <div className="featuredInfo">
                <div className="featuredFigure">
                  <div className="featuredImg">
                    {/* <Link to={`/product/${item._id}`}> */}

                    <Link to={`/product/${item?.uid}/${item.slug}`}>
                      {/* <img src={item?.variations[0]?.mainImage_url ? item.variations[0]?.mainImage_url?.url : item.variations[0]?.mainImage_url?.url} alt="Product" className="imgProduct" /> */}
                      <img
                        src={item?.imgUrls}
                        alt="Product"
                        className="imgProduct"
                      />
                    </Link>
                  </div>
                </div>
                <div className="featuredContent">
                  <h6>category</h6>
                  <h5>
                    <Link to={`/product/${item?.uid}/${item.slug}`}>{item.name}</Link>
                  </h5>

                  <div className="rateDigit">
                    <span className="currentPrice">3000</span>
                    {/* <span className="currentPrice">{currencySymbol} {item?.variations[0]?.prices?.purchase_rate}</span> */}
                  </div>

                  <div className="buyNowInfo">
                    <Link
                      className="btn btn-danger buyNow"
                      to={`#`}
                      onClick={() => { BuyNowItem(item) }}
                    // to={`/product/${item._id}`}
                    >
                      Buy Now
                    </Link>

                    <Link
                      to={`#`}
                      onClick={() => handleAddCart(item)}
                      // to={`/product/${item?.uid}/${item.slug}`}
                      className="buyNow"
                    >
                      {t('Add to Cart')} <i className="ri-arrow-right-up-fill"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <button type="button" onClick={changeLoad} className="btn btn-info" disabled={count > data?.length}>{t('Load More')}</button>
      </div> */}

      {error && (
        <div className="alertMsg mb-4" role="alert">
          No Data Found
        </div>
      )}
    </>
  );
}

export default FeaturedProduct;
