import Slider from "react-slick";
import img1 from '../../../assets/garment/handCraft/HANDCRAFTED_BANNER_1_1445x.webp'
import img2 from '../../../assets/garment/handCraft/HANDCRAFTED_BANNER_1_1_1445x.webp'
import img3 from '../../../assets/garment/handCraft/HANDCRAFTED_BANNER_2_2_2048x (1).webp'
import img4 from '../../../assets/garment/handCraft/HANDCRAFTED_BANNER_2_2_2048x.webp'
import img5 from '../../../assets/garment/handCraft/HANDCRAFTED_BANNER_2_3_2048x.webp'
import img6 from '../../../assets/garment/handCraft/HANDCRAFTED_BANNER_2_5_2048x.webp'

function HandCraftSlide() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3
    };
    return (
        <>
            <div className="draft-slider">
                <Slider {...settings}>
                    <div>
                        <img src={img1} alt="" />
                    </div>
                    <div>
                        <img src={img2} alt="" />
                    </div>
                    <div>
                        <img src={img3} alt="" />
                    </div>
                    <div>
                        <img src={img4} alt="" />
                    </div>
                    <div>
                        <img src={img5} alt="" />
                    </div>
                    <div>
                        <img src={img6} alt="" />
                    </div>
                    <div>
                        <img src={img1} alt="" />
                    </div>
                    <div>
                        <img src={img2} alt="" />
                    </div>
                    <div>
                        <img src={img3} alt="" />
                    </div>
                </Slider>
            </div>

        </>
    )
}
export default HandCraftSlide