import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../../assets/garment/logo/LOGO_1.jpeg";
import menuImg from "../../../assets/garment/handCraft/MENU_TILE-01_1.webp";
import { Link, useNavigate } from "react-router-dom";
import { BiPhoneCall, BiUser } from "react-icons/bi";
import {
  AiOutlineUser,
  AiOutlineHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { BsCart, BsHandbag } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import "./SearchBox.css";
import { useRef } from "react";
import {
  useGetCartQuery,
  getProducts,
  setUpdatedProducts,
  setObjectVal,
  useGetWishListQuery,
  setCartCalc,
  useGetCategoriesQuery,
  useGetMenuListQuery,
  setWishCalc,
  setCartLeng,
} from "../../../components/products/productSlice";
import axios from "axios";
import { useTranslation } from "react-i18next";

function SearchBox({ showMenu, val }) {
  const { t, i18n } = useTranslation()
  const userid = window.localStorage.getItem("user_id");
  const isLogin = window.localStorage.getItem("isLogin");
  const token = window.localStorage.getItem("token");
  const nums = window.localStorage.getItem("callNum");
  const [click, setClick] = useState(false);

  const { data, isSuccess, isFetching } = useGetCartQuery(token);
  // const { data: categoryData } = useGetMenuListQuery();

  const [categoryData, setcategoryData] = useState(null);

  const getData3 = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/category/public`, { withCredentials: true }
    );
    setcategoryData(res.data);
  };

  useEffect(() => {
    getData3();
  }, []);


  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  // const { data: value } = useGetWishListQuery(userid);
  const value = []
  const [trendSearch, setTrendSearch] = useState(null);
  const inputRef = useRef();
  const handleRef = useRef();
  const dispatch = useDispatch();

  const [valuewish, setValuewish] = useState();

  const getDatas = async () => {
    try {
      const res = await axios.get(
        `https://onlineparttimejobs.in/api/user/wishlist`, {
        withCredentials: true,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
      );
      dispatch(setWishCalc(res.data.length))
    } catch (error) {

    }
    if (!token) {
      return
    }
    try {
      const res2 = await axios.get(`https://onlineparttimejobs.in/api/cart`, {
        withCredentials: true,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
      dispatch(setCartLeng(res2.data?.cart?.products.length))
    } catch (error) {

    }

  };

  useEffect(() => {
    getDatas();
  }, []);

  const [state, setState] = useState();

  const handleShow = () => {
    setShow(!show);
  };
  const handleShow2 = () => {
    setShow(false);
  };

  const { updatedProducts, products, cartLengh, WishLengh } = useSelector((item) => {
    return item.productList;
  });
  useEffect(() => {
    if (data) {
      dispatch(getProducts(data.cart.products));
      dispatch(setObjectVal(data));
      if (isLogin === "true") {
        dispatch(setUpdatedProducts(data.cart));
      }
      // dispatch(setCartLeng(data.cart.products.length));
    }
  }, [data]);

  const handleClick = async () => {
    let currentVal = inputRef.current.value.toLowerCase();
    const res = await axios.get(`https://onlineparttimejobs.in/api/product/search/${inputRef.current.value}`, { withCredentials: true })
    navigate(`/products/${currentVal}`);
    currentVal = ""
    setShow(false)
    getData()
  }

  const getData = async () => {
    const resData = await axios.get(
      `https://onlineparttimejobs.in/api/product/trendingSearches`
    );
    setTrendSearch(resData?.data);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!handleRef.current) {
      return;
    }
    let handler = (e) => {
      if (show === true) {
        if (!handleRef?.current?.contains(e.target)) {
          setShow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return function () { };
  }, []);

  useEffect(() => {
    if (isLogin === "true") {
      setState(data);
    } else {
      setState([]);
    }
  }, [isFetching]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick()
    }
  };
  const onSearch = (onSearch) => {
    navigate(`/products/${onSearch}`);
    setShow(false)
  }


  const [data2, setData2] = useState()
  const changeApiData = async (e) => {
    if (e.target.value?.length > 2) {
      try {
        const resData = await axios.get(
          `https://onlineparttimejobs.in/api/product/search/${e.target.value}`
        );
        setData2(resData.data?.getSearchedProduct)
      } catch (error) {

      }

    }
  }
  const changeROutes = (item) => {
    navigate(`/product/${item.uid}/${item.slug}`);
    setShow(false)
    setData2([])
  }
  return (
    <>
      <div className="head-head">
        <section className="searchSec">
          <div className="container">
            <div className="searchItem">
              <div className="logo mamas">
                <Link to="/">
                  {/* <img src={val?.icon?.url ? val?.icon?.url : brandLogo} alt="logo" /> */}
                  <img src={logo} alt="logo" onClick={handleShow2} />
                  {/* <div>
                  <h5 className="m-0 " style={{ color: " #5e0c36", textTransform: "uppercase" }}>Mamastycoon</h5>
                  <span>First Steps</span>
                </div> */}
                </Link>
                <div className="bar">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <FaBars onClick={showMenu} />
                  </button>
                </div>
              </div>
              {/* <div className="searchSec">


                <div className="searchInf">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Search')}
                    onClick={handleShow}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    onChange={changeApiData}
                  />
                  <div className="rightSearchInfo">
                    <div className="allCategory">
                      <select defaultValue="all category">
                        <option value="All Category">{t('All Category')}</option>
                        {categoryData?.map((item) => {
                          return (
                            <option key={item?._id}>
                              <Link to={`product/category/${item._id}/${item.slug}`}>{item?.name}</Link>
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="searchIcon">
                      <BiSearch onClick={handleClick} />
                      
                    </div>
                  </div>
                </div>
                {show && (
                  <div className="treandingSec" id="DropShow" ref={handleRef}>
                    <div className="trendingItem">
                      {data2?.length > 0 && <div className="mainnaed">
                        {data2 && data2?.map((item) => {
                          return <div className="mainViy" onClick={() => { changeROutes(item) }}>
                            <img src={item?.variations[0]?.mainImage_url?.url} style={{ border: "1px solid gray", width: "80px", height: "80px" }} />
                            <div>
                              <h6>{item?.name}</h6>
                              <h6>QAR 23.4</h6>
                            </div>
                          </div>
                        })}

                      </div>}


                      <h5 className="trendingTitle">Trending Search</h5>
                      <ul>
                        {trendSearch && trendSearch?.map((item, i) => {

                          return <li key={i}>
                            <Link to={`/products/${item.query}`} onClick={() => onSearch(item.query)}> {item.query}</Link>
                          </li>
                        })}
                      </ul>
                      <AiOutlineCloseCircle className="closeBtnDrop" onClick={handleShow} />
                    </div>
                    
                  </div>
                )}
              </div> */}

              <div className="menu-set">
                <ul>
                  <li>
                    <a href="/">
                      <span>HOME</span>
                    </a>
                  </li>
                  <li>
                    <Link to="about">
                      <span>ABOUT</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#">
                      <span>CLOTHING</span>
                    </a>
                    <div className="dropdown-box">
                      <div className="d-menu">
                        <div className="head">
                          <h3>WOMEN</h3>
                          <ul>
                            <li><a href="#">DRESSES</a></li>
                            <li><a href="#">SILK</a></li>
                            <li><a href="#">CO-ORD SETS</a></li>
                            <li><a href="#">TOPS</a></li>
                            <li><a href="#">BOTTOMS</a></li>
                            <li><a href="#">JACKETS</a></li>
                          </ul>
                        </div>
                        <div className="head">
                          <h3>MEN</h3>
                          <ul>
                            <li><a href="#">SHIRTS</a></li>
                            <li><a href="#">JACKETS</a></li>
                            <li><a href="#">BOTTOMS</a></li>
                            <li><a href="#">SILK</a></li>

                          </ul>
                        </div>
                        <div className="head">
                          <h3>COLLECTIONS</h3>
                          <ul>
                            <li><a href="#">KOKUM</a></li>
                            <li><a href="#">THE LAST QUEEN</a></li>
                            <li><a href="#">MANDAI</a></li>
                            <li><a href="#">PAHARI</a></li>
                            <li><a href="#">NORBU</a></li>
                            <li><a href="#">SOMETHING NEW</a></li>
                            <li><a href="#">LUNA</a></li>
                            <li><a href="#">THE COLLECTOR</a></li>

                          </ul>
                        </div>
                        <div className="head">
                          <h3>ACCESSORIES</h3>
                          <ul>
                            <li><a href="#">BAGS</a></li>


                          </ul>
                        </div>
                        <div className="head">
                          <h3>LOOKBOOKS</h3>
                          <ul>
                            <li><a href="#">GIFT CARDS</a></li>
                          </ul>
                        </div>
                        <div className="head">
                          <img src={menuImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <span>JEWELLERY</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>JODI MAGAZINE</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>RELOVE</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="searchIcons">
                {/*  <div className="optionSelect">
                <div className="lang1">
                  <select name="language" id="languId" className="form-select">
                    <option value="63fb926bba4c51937001628a">English</option>
                    <option value="DEFAULT" selected>
                      Hindi
                    </option>
                  </select>
                </div>
                <div className="rupees1">
                  <select name="currency" id="currId" className="form-select">
                    <option value="DEFAULT" selected>
                      {" "}
                      QAR{" "}
                    </option>
                  </select>
                </div>
              </div> */}

                <div className="searchIconsItem">
                  <ul>
                    <li>
                      <Link to="/myAccount">
                        <BiUser />
                      </Link>
                    </li>
                    <li className="countWish">
                      <Link to="/wishlist">
                        <AiOutlineHeart />
                        {WishLengh > 0 && <span className="count">{WishLengh}</span>}
                      </Link>
                    </li>
                    <li className="countParent">
                      <Link to="/cart">
                        <BsCart />
                        {cartLengh > 0 && <span className="count">{cartLengh}</span>}


                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div >
        </section >
      </div>
    </>
  );
}

export default SearchBox;
