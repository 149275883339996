import Breadcrumb from "../../shared/breadcrumb/Breadcrumb"


function ReturnExchangePolicy() {
    return (
        <>
            <Breadcrumb title="Returns Exchange Policy" />
            ReturnExchangePolicy
        </>
    )
}
export default ReturnExchangePolicy